// -sl
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/syc/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/syc/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/syc/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "partnerclass",
          title: "往来单位分类同步",
          name: "partnerclass",
          icon: "el-icon-document",
          component: "views/syc/PartnerClass.vue"
          ,visible:true
  
        },
        {
          pageFlag: true,
          id: "partner",
          title: "往来单位同步",
          name: "partner",
          icon: "el-icon-document",
          component: "views/syc/Partner.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "aaaccount",
          title: "会计科目同步",
          name: "aaaccount",
          icon: "el-icon-document",
          component: "views/syc/AaAccount.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "define01",
          title: "费用项目同步",
          name: "define01",
          icon: "el-icon-document",
          component: "views/syc/Define01.vue"
          ,visible:true
        }
      ]
      }
]
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}
//other
__system = {
  __activeid: "",
  __menu: [
    // {
    //   pageFlag: true,
    //   id: "test",
    //   title: "test",
    //   name: "test",
    //   icon: "el-icon-document",
    //   component: "views/syc/Test.vue"
    //   ,visible:true
    // },
  {
    pageFlag: false,
    id: "offer",
    title: "销售管理",
    icon: "el-icon-menu",
    children: [
      {
        pageFlag: true,
        id: "mporder",
        title: "销售订单导入",
        name: "mporder",
        icon: "el-icon-document",
        component: "views/offer/SaleList.vue",
        componentdetail: ""
        ,visible:true
      }
	/**  ,
    {
      pageFlag: false,
      id: "offerbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "basprice",
        title: "当前钢价",
        name: "basprice",
        icon: "el-icon-document",
        component: "views/offer/Basprice.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "basinventory",
        title: "配件存货",
        name: "type",
        icon: "el-icon-document",
        component: "views/offer/BasInventoryList.vue",
        componentdetail: "views/offer/BasInventoryDetail.vue",
        visible:true
      },
      {
        pageFlag: true,
        id: "skuinventory",
        title: "MP_SKU测试",
        name: "type",
        icon: "el-icon-document",
        component: "views/offer/SkuInventoryList.vue",
        componentdetail: "views/offer/SkuInventoryDetail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "offercommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: false,
          id: "offer",
          title: "报价单",
          name: "offer",
          icon: "el-icon-document",
          component: "views/offer/OfferList.vue",
          componentdetail: "views/offer/OfferDetail.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "mporder",
          title: "生产加工单",
          name: "mporder",
          icon: "el-icon-document",
          component: "views/offer/MpOrderList.vue",
          componentdetail: "views/offer/MpOrderDetail1.vue"
          ,visible:true
        }
      ],
   
      },
    */
      
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld